import React, { useContext, useEffect } from "react"

import LayoutContext from "../../components/LayoutContext"
import HelmetHead from "../../components/HelmetHead"
import LeadGeneratorLoadable from "../../components/generator/LeadGeneratorLoadable"
import { ArrowRight, ArrowUp, Check, Phone } from "react-feather"
import StepImgNo from "../../components/StepImgNo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import LoadGatsbyOptimizedImage from "../../components/LoadGatsbyOptimizedImage"
import Loadable from "@loadable/component"
import Image from "../../components/Image"
import ClipboardList from "../../svgs/icons/duotone-icons/Communication/Clipboard-list.svg"
import User from "../../svgs/icons/duotone-icons/General/User.svg"
import DoneCircle from "../../svgs/icons/duotone-icons/Code/Done-circle.svg"
import Accordion from "react-bootstrap/Accordion"
import AccordionHeaderCustomScroll from "../../components/AccordionHeaderCustomScroll"
const RatingSlider = Loadable(() => import("../../components/RatingSlider"))

export const query = graphql`
  query CitiesQuery {
    allStrapiCity(limit: 40) {
      edges {
        node {
          id
          city
          slug
        }
      }
    }
  }
`

const Maklerempfehlung = ({ data }) => {
  const [layout, setLayout] = useContext(LayoutContext)

  // define if lead-generator is used in page!
  const canonicalPathname = `/de/maklerempfehlung/`
  const fixedPathname = `/de/maklerempfehlung/`

  const cities = data.allStrapiCity.edges

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        // minimal: true,
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))
  }, [])

  const images = LoadGatsbyOptimizedImage()
  const img = "Maklerempfehlung.png"
  const womanLandingpage = images["custom"].edges.find(
    ({ node }) =>
      node.childImageSharp &&
      node.childImageSharp.gatsbyImageData.images.fallback.src
        .split("/")
        .pop() === img
  ).node.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <>
      <HelmetHead
        title="Erstklassige Maklerempfehlung von der Maklerzentrale"
        description="Erstklassige Maklerempfehlung. Starke Leistungen und wertvolle Tipps für Käufer und Verkäufer. Finden auch Sie den richtigen Makler für Ihre Immobilie. "
        keywords="Maklerempfehlung, Immobilienmakler finden, Makler Empfehlung"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <section className="section-border border-primary">
        <div
          className="container-fluid pt-6 pt-md-10 maklerempfehlung-landing-hide-hero"
          style={{
            backgroundImage: `url(${womanLandingpage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right bottom",
            backgroundSize: "auto 100%",
          }}
        >
          {/*<h1*/}
          {/*  className="mx-auto text-center"*/}
          {/*  style={{ fontSize: "28px", maxWidth: "767px" }}*/}
          {/*>*/}
          {/*  Finden Sie den richtigen Makler für den Verkauf Ihrer Immobilie mit*/}
          {/*  einer kostenfreien Immobilienbewertung!*/}
          {/*</h1>*/}
          <div
            className="card card-bleed shadow-light-lg mx-md-auto"
            style={{ minHeight: "495px", maxWidth: "767px" }}
          >
            <div className="scroll-beacon" id="makler-finden" />
            <LeadGeneratorLoadable
              leadGenerator="makler"
              setPath={fixedPathname}
            />
          </div>
          <div className="row justify-content-center mt-5">
            <a
              href="https://g.page/Maklerzentrale?share"
              target="_blank"
              className="col-auto"
            >
              <Image
                src="google-bewertungen.png"
                alt="Google Bewertung"
                // className="mb-2"
                style={{ height: "auto", width: "150px" }}
              />
            </a>
            <a
              href="https://de.trustpilot.com/review/maklerzentrale.com"
              target="_blank"
              className="col-auto"
            >
              <Image
                src="trustpilot.png"
                alt="Trustpilot Bewertung"
                className=""
                style={{ height: "auto", width: "150px" }}
              />
            </a>
          </div>
        </div>
      </section>

      <section className="py-7 py-md-9 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="so-funktionierts" />
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold mb-2">
                Gemeinsam finden wir den richtigen Makler
              </h2>
              <h3 className="fw-bold mb-9 mb-md-11">
                in 3 einfachen Schritten
              </h3>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block" />
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="ImmobilieGartenCenter.jpg"
                    alt="Haus - Daten Immobilie"
                    no={1}
                    dataSal="slide-down"
                    dataSalDelay="200"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Daten zu Ihrer Immobilie</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                Sie geben die Eckdaten zu Ihrer Immobilie in das Formular ein.
                Die Art der Immobilie, der Standard, die Nutzung und der
                Standort haben großen Einfluss auf die Wahl des optimalen
                Immobilienmaklers. Spezialisierte Immobilienmakler erzielen ein
                besseres Ergebnis.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Fotolia_61667417_S.jpg"
                    alt="Maklerempfehlung"
                    no={2}
                    dataSal="slide-down"
                    dataSalDelay="500"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
              </div>
              <h3 className="fw-bold px-2">Maklerempfehlung</h3>
              <p className="text-muted mb-6 mb-md-0 px-2">
                Nach eingehender Recherche der Immobilienmakler mit
                Marktkompetenz für vergleichbare Immobilien, nennen wir Ihnen
                1-3 Immobilienmakler, die unsere hohen Ansprüche an Fach- und
                Marktkenntnis, Transparenz, Diskretion und Zuverlässigkeit
                erfüllen und seit Jahren am Markt etabliert sind.
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="row mb-5 justify-content-center">
                <div className="col d-none d-lg-block">
                  <hr className="border-primary-desat" />
                </div>
                <div className="col-auto mt-8 mt-md-0">
                  <StepImgNo
                    src="Online-Marketing.jpg"
                    alt="Wahl Makler"
                    no={3}
                    dataSal="slide-down"
                    dataSalDelay="800"
                    dataSalDuration="700"
                  />
                </div>
                <div className="col d-none d-lg-block" />
              </div>
              <h3 className="fw-bold px-2">Sie haben die Wahl</h3>
              <p className="text-muted mb-0 px-2">
                Sie erhalten unsere Maklerempfehlung per E-Mail und können im
                Anschluss daran besser vergleichen. Wenn Sie den empfohlenen
                Makler in Betracht ziehen, kontaktieren Sie ihn, um ihn im
                Rahmen einer unverbindlichen Erstberatung persönlich
                kennenzulernen.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-7 py-md-9 bg-gray-200 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="kostenfreie-maklerempfehlung" />
          <h2 className="fw-bold">Immobilienmakler finden</h2>
          <p className="text-muted">
            Der Verkauf einer Immobilie ist eine wichtige Entscheidung, die
            letztendlich die ganze Familie betrifft. Daher ist es
            empfehlenswert, sich professionelle Unterstützung zu sichern, sei es
            bei der Ermittlung des Verkaufspreises, der Wahl der
            Verkaufsstrategie oder bei anderen Aspekten des Immobilienverkaufs.
            Ein erfahrener Immobilienmakler verfügt nicht nur über grundlegende
            Informationen zum Verkauf einer Immobilie, sondern weiß unter
            anderem auch, wie sie Kaufpreisreduzierungen entgegenwirken und zu
            den besten Konditionen verkaufen können.
            <br />
            Bevor Sie einen Immobilienmakler beauftragen, sollten Sie die
            folgenden Punkte berücksichtigen:
          </p>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Ist er auf dem Immobilienmarkt etabliert?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Über welche Auszeichnungen, Lizenzen und Qualifikationen
                  verfügt er?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Wie wird der Verkaufspreis ermittelt?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Hat er bereits Verkäufe im gleichen Stadtteil abgeschlossen?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Was beinhaltet sein Honorar?</p>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Auf welche Immobilien hat er sich spezialisiert?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Ist dieser Mitglied in einem Berufsverband?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Wie wird er die Immobilie vermarkten?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Welche Referenzen hat er vorzuweisen?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Welchen Service bietet er an und welchen nicht?
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Welche Informationen benötigt er von Ihnen?
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-7 py-md-9">
        <div className="container">
          <h2 className="text-center">Tipps zur Maklerwahl</h2>
          <h3 className="text-muted">
            Wenn Sie Ihre Immobilie mithilfe eines Immobilienmaklers verkaufen
            möchten, müssen Sie nur noch den richtigen finden. Klingt einfach,
            aber welcher ist der richtige?
          </h3>
          <h3 className="mt-6 mb-4">Sie haben die Wahl</h3>
          <p className="text-muted mb-2">
            Die Wahl eines Immobilienmaklers ist entscheidend für einen
            erfolgreichen Verkauf - aber wie erkennt man einen guten
            Immobilienberater?
          </p>
          <p className="text-muted mb-2">
            Es ist wichtig, dass Sie den besten Immobilienmakler für sich und
            Ihre Immobilie finden, wenn Sie eine Immobilie verkaufen möchten.
          </p>
          <p className="text-muted">
            Es ist ratsam zu beachten, dass Makler gegen Provision arbeiten.
            Sollten Sie daher einen gewissen Druck verspüren, ein Angebot
            anzunehmen, erinnern Sie sich daran, dass diese Entscheidung allein
            bei Ihnen liegt. Ein verantwortungsbewusster Immobilienmakler wird
            Sie nie zu etwas drängen, womit Sie sich nicht wohlfühlen.
          </p>

          <Accordion
            defaultActiveKey="makler-transparenz"
            className="card shadow-light-lg mt-5 mt-md-7 mb-5 mb-md-6"
          >
            <Accordion.Item eventKey="makler-transparenz">
              <AccordionHeaderCustomScroll anchor="makler-transparenz">
                Erfolgreich mit Ehrlichkeit und Transparenz
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ehrlichkeit ist der Boden, auf dem Vertrauen wächst. Daher
                  sollte Ihr Immobilienmakler Ihnen auch negative Dinge der
                  Immobilie darlegen. Darüber hinaus sollten Sie nie das Gefühl
                  haben, dass Ihr Immobilienberater Ihnen nach dem Mund redet
                  oder versucht, Sie zu etwas zu überreden. Transparenz und ein
                  aufrichtiges Miteinander sind für eine Zusammenarbeit auf
                  Augenhöhe entscheidend.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-empfehlung">
              <AccordionHeaderCustomScroll anchor="makler-empfehlung">
                Immobilienmakler empfehlen lassen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Als erste Anlaufstelle können Sie sich bei der Maklerzentrale
                  einen erprobten Makler empfehlen lassen, mit dem bereits viele
                  Eigentümer gute Erfahrungen gemacht haben. Recherchieren Sie
                  darüber hinaus im Internet nach verschiedenen
                  Immobilienmaklern und vergleichen Sie die Empfehlungen anderer
                  Nutzer - unabhängige Bewertungsplattformen wie Google liefern
                  einen guten Eindruck über die Beratungsqualität. Achten Sie
                  dabei nicht nur auf die Bewertung an sich, sondern auch die
                  Anzahl der Bewertungen. Ein Makler mit vielen zufriedenen
                  Kunden wird entsprechend viele positive Bewertungen vorweisen
                  können.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-spezalisierung">
              <AccordionHeaderCustomScroll anchor="makler-spezalisierung">
                Spezialisierung eines Immobilienmaklers
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Nicht nur die Lage Ihrer Immobilie ist für den Verkauf
                  entscheidend, sondern auch die des Immobilienmaklers. Achten
                  Sie bei der Wahl eines Maklers darauf, wo er tätig ist und auf
                  welche Art von Immobilien er spezialisiert ist. Der Makler
                  sollte vergleichbare Objekte in seinem Bestand haben und sich
                  auf die Region spezialisiert haben, in der auch Ihre Immobilie
                  liegt. Nur so ist er mit der aktuellen Nachfrage wirklich
                  vertraut und kann den Kaufinteressenten hilfreiche Tipps zur
                  Mikrolage geben, wie z.B. zur Nachbarschaft, zur
                  nächstgelegene Schule oder zu Bebauungsplänen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-qualitaet">
              <AccordionHeaderCustomScroll anchor="makler-qualitaet">
                Qualität der Immobilienberatung
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Im Internet können Sie viel über einen Makler herausfinden.
                  Welchen Eindruck macht seine Website, ist er in Social-Media
                  präsent und aktiv? Sind die Immobilienberater mit Bild und
                  persönlichen Kontaktdaten professionell präsentiert? Wie
                  auffällig und ansprechend ist die Aufmachung der Exposés? Sind
                  die Fotos der angebotenen Immobilien von einem Profi
                  aufgenommen worden und werbefrei? Sind die Beschreibungen der
                  Immobilien aussagekräftig und einladend? Erstellt der Makler
                  professionelle Grundrisse unter Berücksichtigung
                  zielgruppengerechter Nutzungsmöglichkeiten?
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-referenzen">
              <AccordionHeaderCustomScroll anchor="makler-referenzen">
                Referenzen eines Immobilienmaklers
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ein passender Immobilienmakler sollte erfahren sein und
                  vergleichbare Immobilien vermittelt haben. Nur so können Sie
                  sicher sein, dass er mit den Besonderheiten ähnlicher
                  Immobilien vertraut ist. So können die Gegebenheiten eines
                  modernen Neubaus ganz andere sein als die eines eigengenutztes
                  Einfamilienhauses. Darüber hinaus hilft der Blick auf
                  unabhängige Studien, wie das Deutsche Institut für
                  Service-Qualität oder die erstklassige Maklerempfehlung der
                  Maklerzentrale, die besonders herausragende Makler auszeichnet
                  und weiterempfiehlt.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-kompetenzen">
              <AccordionHeaderCustomScroll anchor="makler-kompetenzen">
                Kompetenzen eines Immobilienmaklers
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ein guter Immobilienmakler sollte eine entsprechende
                  Ausbildung vorweisen: sei es eine Berufsausbildung als
                  Immobilienmakler, die nicht verpflichtend ist, oder eine
                  akademische Ausbildung in entsprechenden Bereichen wie
                  Immobilienwirtschaft oder Architektur. Regelmäßige
                  Weiterbildungen für Zusatzqualifikationen in berufsnahen
                  Themen wie Immobilienrecht beispielsweise, sollte
                  vorausgesetzt werden können.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="makler-sympathie">
              <AccordionHeaderCustomScroll anchor="makler-sympathie">
                Sympathie zum Immobilienmakler
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ein Immobilienmakler wird Sie während des gesamten
                  Verkaufsprozesses begleiten und Ihre Immobilie gegenüber
                  Kaufinteressenten mit seiner Persönlichkeit und dem
                  Unternehmen repräsentieren. Sie sollten daher darauf achten,
                  dass der Immobilienmakler Ihnen persönlich sympathisch ist.
                  Ein geeigneter Käufer wird dies ähnlich empfinden. Ein
                  weiterer Vorteil ist, dass falls es mal zu einer schwierigen
                  Situation kommen sollte, wird diese erheblich leichter zu
                  lösen sein, wenn das Verhältnis durch gegenseitige Sympathie
                  und ein freundschaftliches Miteinander geprägt ist. Ein
                  Immobilienmakler sollte zur Immobilie, aber auch zu Ihnen
                  passen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>

      <section
        className=""
        // style={{ backgroundColor: "#eeefee" }}
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-6 order-md-2">
              <h2>Experten der Maklerzentrale</h2>
              <p className="text-muted">
                Die Maklerzentrale hilft Ihnen dabei, den richtigen Makler für
                den Verkauf Ihrer Immobilie zu finden: kostenfrei, unabhängig
                und zuverlässig. Unsere kompetenten Berater stehen Ihnen mit
                Zahlen, Daten und Fakten über Ihren Immobilienmakler zur
                Verfügung. Unser Service wird von unseren Partnern finanziert
                und bleibt für Eigentümer in jedem Fall 100% kostenfrei.
                Profitieren Sie von unserer langjährigen Erfahrung mit
                erstklassigen Immobilienmaklern.
              </p>
              <hr className="d-none d-lg-block border-gray-300" />
              <h4>Kostenlose Beratung durch Experten der Maklerzentrale</h4>
              <p className="mb-0">
                Jetzt anrufen <Phone size={18} className="me-1" />{" "}
                <a href="tel:+498002242020">0800 2242020</a>
              </p>
              <p className="mb-4">
                Montag - Freitag, 9:00 -20:00 Uhr
                <br />
                <Link
                  to="/de/kontakt#unverbindliche-anfrage"
                  className="btn btn-sm btn-primary-desat-soft py-3 px-3 mt-3"
                  style={{ minWidth: "133px" }}
                >
                  Rückruftermin vereinbaren
                </Link>
              </p>
            </div>
            <div className="col-md-6 order-md-1 pt-md-8 pt-lg-0 px-0">
              <Image
                src="Telefon-Mitarbeiter2.png"
                alt="Kontakt Maklerzentrale"
                style={{ maxWidth: "350px" }}
                imgStyle={{ objectFit: "contain" }}
                className="img-fluid mx-auto mt-5 mt-md-0"
              />
            </div>
          </div>
        </div>
        <div className="bg-dark">
          <RatingSlider className="pt-8 pb-2" />
        </div>
      </section>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ClipboardList />
              </div>
              <h4 className="fw-bold text-center">
                Ergebnisse, die Ihre Erwartungen erfüllen
              </h4>
              <p className="text-muted">
                Sie möchten Ihre Immobilie mit einem zuverlässigen
                Immobilienmakler verkaufen, der das beste Ergebnis für Sie
                rausholt und individuell auf Ihre Wünsche eingeht? Mit einer
                erstklassigen Maklerempfehlung finden Sie schnell den richtigen
                Makler und einen geeigneten Käufer.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 order-md-2 order-lg-1">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <User />
              </div>
              <h4 className="fw-bold text-center">
                Ein Immobilienmakler, der Ihren Vorstellungen entspricht
              </h4>
              <p className="text-muted">
                Mit der Maklerzentrale treffen Sie Ihre Entscheidung bewusst.
                Sie können die Kriterien festlegen, die Ihr Immobilienmakler
                erfüllen muss. Wir helfen Ihnen dabei, einen Makler zu finden,
                der zu Ihnen und zu Ihrer Immobilie passt.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 order-md-1 order-lg-2">
              <div
                className="icon mb-4 text-center text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <DoneCircle />
              </div>
              <h4 className="fw-bold text-center">
                Gemeinsam finden wir den richtigen Makler
              </h4>
              <p className="text-muted">
                Mit unserer Maklerempfehlung sind Sie immer auf der sicheren
                Seite. Wir unterstützen Sie dabei, Ihre Maklerwahl so zu
                optimieren, dass Sie Ihr selbst gestecktes Ziel erreichen.
              </p>
            </div>
          </div>

          <div className="row justify-content-center mt-5 mt-md-6">
            <div
              className="col-12 col-md-7 col-lg-6"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="700"
            >
              <Link
                to="/de/maklerempfehlung/"
                className="btn btn-block btn-primary d-flex align-items-center lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-7 pb-md-9 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="hauefig-gesucht" />
          <h2 className="fw-bold mt-8">Häufig gesuchte Regionen</h2>
          <div className="row">
            {cities.map(({ node: otherCity }, i) => (
              <div className="col-4 col-sm-3 col-md-2" key={i}>
                <Link to={`/de/${otherCity.slug}/`}>{otherCity.city}</Link>
              </div>
            ))}
          </div>
          <div className="text-center">
            <a
              href="#"
              className="btn btn-white btn-rounded-circle shadow mb-n11 mb-md-n13"
              // onClick={smoothScroll}
            >
              <ArrowUp size={20} />
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Maklerempfehlung
